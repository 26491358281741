import { Button, Typography, Link, Box } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext'
import { getSettings } from '../../functions/database'
import defaultQuestionsLibrary from '../../constants/defaultQuestionsLibrary'

export default function CheckInThankYou({ handleReturn }) {
  const { db, providerID, answers } = useContext(GlobalContext)
  const [callToActionText, setCallToActionText] = useState(null)
  const [callToActionLink, setCallToActionLink] = useState(null)

  useEffect(() => {
    const loadSettings = async () => {
      const settings = await getSettings(db, providerID)
      if (settings) {
        console.log(settings)

        const rating =
          answers[defaultQuestionsLibrary.questions[1].questionID]?.value
        setCallToActionText(
          rating && rating >= 4
            ? settings.callsToAction[0]?.text
            : settings.callsToAction[1]?.text
        )
        setCallToActionLink(
          rating && rating >= 4
            ? settings.callsToAction[0]?.link
            : settings.callsToAction[1]?.link
        )
      }
    }

    loadSettings()
  }, [db, providerID])

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      height='80vh'
    >
      <Box mb={2}>
        {callToActionText ? (
          callToActionLink ? (
            <Link
              variant='h3'
              href={
                //check link vs. email
                callToActionLink.includes('@')
                  ? `mailto:${callToActionLink}`
                  //check link starts with http://
                  : callToActionLink.includes('http://') || callToActionLink.includes('https://')
                  ? callToActionLink
                  : `http://${callToActionLink}`
              }
              target={callToActionLink.includes('@') ? '_self' : '_blank'}
              rel='noopener noreferrer'
            >
              {callToActionText}
            </Link>
          ) : (
            <Typography variant='h3'>{callToActionText}</Typography>
          )
        ) : (
          <Typography variant='h3'>Thank You!</Typography>
        )}
      </Box>
      <Button color='primary' onClick={handleReturn}>
        Close
      </Button>
    </Box>
  )
}
