import { v4 as uuidv4 } from 'uuid'
import { PutItemCommand, GetItemCommand } from '@aws-sdk/client-dynamodb'

const contactsTable =
  process.env.GATSBY_ENV === 'production' ? 'Contacts' : 'ContactsDev'
const providerFormsTable =
  process.env.GATSBY_ENV === 'production' ? 'ProviderForms' : 'ProviderFormsDev'
const checkinSubmissionsTable =
  process.env.GATSBY_ENV === 'production'
    ? 'CheckinSubmissions'
    : 'CheckinSubmissionsDev'
const settingTable =
  process.env.GATSBY_ENV === 'production'
    ? 'ProviderSettings'
    : 'ProviderSettingsDev'

export const getProviderByID = async (db, providerID) => {
  const getItemParams = {
    TableName: providerFormsTable,
    Key: { AccountID: { S: providerID } }
  }

  try {
    const command = new GetItemCommand(getItemParams)
    const response = await db.send(command)
    return response.Item
  } catch (error) {
    console.error(error)
  }
}

export const saveCheckin = async (db, providerID, answers) => {
  try {
    const date = new Date()
    const putItemParams = {
      TableName: checkinSubmissionsTable,
      Item: {
        SubmissionID: { S: uuidv4() },
        ProviderID: { S: providerID }, // should come from the form data itself, not login data.
        Data: { S: JSON.stringify(answers) },
        SubmissionDate: { S: date.toISOString() }
      }
    }

    const command = new PutItemCommand(putItemParams)
    const response = await db.send(command)
    return response
  } catch (error) {
    console.error('saveCheckinError', error)
    throw error
  }
}

export const getUserByID = async (db, userID) => {
  const getItemParams = {
    TableName: contactsTable,
    Key: { ContactID: { S: userID } }
  }
  try {
    const command = new GetItemCommand(getItemParams)
    const response = await db.send(command)
    return response.Item
  } catch (error) {
    console.error('findUser error', error)
  }
}

export const saveUser = async (db, userID, userData) => {
  try {
    const putItemParams = {
      TableName: contactsTable,
      Item: {
        ContactID: { S: userID },
        Data: { S: JSON.stringify(userData) }
      }
    }

    const command = new PutItemCommand(putItemParams)
    const response = await db.send(command)
    return response
  } catch (error) {
    console.error(error)
  }
}

export const getSettings = async (db, accountID) => {
  const getItemParams = {
    TableName: settingTable,
    Key: { AccountID: { S: accountID } }
  }

  try {
    const command = new GetItemCommand(getItemParams)
    const response = await db.send(command)
    return response?.Item?.Settings?.S
      ? JSON.parse(response.Item.Settings.S)
      : null
  } catch (error) {
    console.error(error)
  }
}
